import { Row, Col } from 'react-bootstrap'
import { NavLink } from "react-router-dom"

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import variablePie from 'highcharts/modules/variable-pie';

import videdo from './../../assets/video/banner.mp4'

const Home = () => {
    variablePie(Highcharts)
    const options = {
        chart: {
            type: 'variablepie',
            options3d: {
                enabled: true,
                alpha: 45,
                beta: 0
            }
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: 'Percentage: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            variablepie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
                ,
                showInLegend: true
            }
        },
        series: [{
            minPointSize: 10,
            innerSize: '20%',
            zMin: 0,
            name: 'countries',
            borderRadius: 5,
            data: [
                {
                    name: 'Supply Chain',
                    y: 10,
                    z: 10
                },
                {
                    name: 'Real Estate',
                    y: 10,
                    z: 10
                },
                {
                    name: 'International Market',
                    y: 10,
                    z: 10
                },
                {
                    name: 'Startup Ventures',
                    y: 15,
                    z: 15
                },
                {
                    name: 'Tech',
                    y: 20,
                    z: 20
                },
                {
                    name: 'Stock Market',
                    y: 35,
                    z: 35
                }
            ],
            colors: [
                '#4caefe',
                '#3dc3e8',
                '#2dd9db',
                '#1feeaf',
                '#0ff3a0',
                '#00e887'
            ]
        }]
    }

    return (
        <>
            <section className="home-banner">
                <video autoPlay loop muted playsInline style={{ width: '100%' }}>
                    <source src={videdo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="home-banner-text">
                    Welcome to Algo Investments Group
                    <br></br>
                    <span> (A Synthesis of Expertise and Innovation)</span>
                </div>
            </section>
            <section className="container">
                <div className="section-title text-center">
                    Exclusive, Expert-Led Investment Opportunities
                </div>
                <div className="section-value text-center">
                    At Algo Investments Group, we specialize in managing and growing wealth with precision and expertise. As an invitation-only investment group, we offer our members an exclusive opportunity to be part of a community that values discretion, professionalism, and results.
                </div>
            </section>
            <section className="bg-light">
                <div className="container">
                    <Row>
                        <Col lg={4} md={4} className="statics">
                            <div className="title">$55m</div>
                            <div className="separator"></div>
                            <div className="value">Portfolio</div>
                        </Col>
                        <Col lg={4} md={4} className="statics">
                            <div className="title">50+</div>
                            <div className="separator"></div>
                            <div className="value">Club Member</div>
                        </Col>
                        <Col lg={4} md={4} className="statics">
                            <div className="title">30 yrs</div>
                            <div className="separator"></div>
                            <div className="value">Experience</div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="bg-gradient">
                <div className="container">
                    <div className="section-title text-center">
                        Our Unique Value Proposition
                    </div>
                    <Row className="m-0">
                        <Col lg={4} md={4} className="key-points">
                            <div className="title">Tailored Investment Strategies<i className="bi bi-chevron-right"></i></div>
                            <div className="value">Leveraging cutting-edge algorithms and market insights, we develop bespoke investment strategies designed to maximize returns.</div>
                        </Col>
                        <Col lg={4} md={4} className="key-points">
                            <div className="title">Expert Management<i className="bi bi-chevron-right"></i></div>
                            <div className="value">Our team comprises seasoned investment professionals with a deep understanding of financial markets.</div>
                        </Col>
                        <Col lg={4} md={4} className="key-points">
                            <div className="title">Exclusivity and Privacy<i className="bi bi-chevron-right"></i></div>
                            <div className="value">Membership is by invitation only, ensuring a private and exclusive environment for our investors.</div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="container">
                <div className="section-title text-center">
                    Join a Community of Select Investors
                </div>
                <div className="section-value text-center">
                    Algo Investments Group isn't just about investments; it's about joining a community of discerning individuals who understand the value of expert-led financial strategies. Our members enjoy the benefits of collective wisdom coupled with individualized attention.
                </div>
            </section>
            <section className="bg-image">
                <div className="container">
                    <Row>
                        <Col lg={6} md={6}>
                            <div className="section-title">
                                Explore What We Offer
                            </div>
                            <div className="section-value">
                                Browse our website to learn more about our approach, meet our team, and understand how our investment process works. If you are looking for a dedicated group that aligns with your financial aspirations, Algo Investments Group is your ideal partner in wealth management.
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="container focus-area">
                <div className="section-title text-center mb-4 pb-4">
                    <b>FOCUS AREA</b>
                </div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </section>
            <section className="container">
                <div className="section-title text-center">
                    Interested in Joining?
                </div>
                <div className="section-value text-center">
                    For inquiries or to express your interest in receiving an invitation, please visit our Contact Us page. We look forward to exploring how we can help you achieve your investment goals.
                </div>
                <div className="conneect-now">
                    <NavLink to="/contactUs" className="n-button">
                        Connect Now
                    </NavLink>
                </div>
            </section>
        </>
    )
}

export default Home