import { Row, Col } from 'react-bootstrap'
import { NavLink } from "react-router-dom"

import team from './../../assets/image/team.jpeg'
import vision from './../../assets/image/vision.jpeg'

const AboutUs = () => {
    return (
        <>
            <section className="home-banner about-us-banner">
                <div className="page-title">About Us</div>
                <div className="home-banner-text"></div>
            </section>
            <section className="container">
                <div className="section-title text-center">
                    Our Story
                </div>
                <div className="section-value text-center">
                    Founded with a vision to redefine investment management, Algo Investments Group has evolved into a premier, invitation-only investment circle. Our genesis was motivated by a desire to create a platform that not only excels in generating returns but also values the trust and exclusivity sought by our members.
                </div>
            </section>
            <section className="container our-team">
                <Row>
                    <Col lg={6} md={6}>
                        <div className="section-title">
                            Our Team: The Core of Our Success
                        </div>
                        <div className="section-value">
                            At the heart of Algo Investments Group is a diverse team of investment professionals, each bringing a wealth of experience and specialized knowledge. Our team includes seasoned financial analysts, market strategists, and risk management experts. We pride ourselves on our collective expertise, which is the cornerstone of our success and the success of our members.
                        </div>
                    </Col>
                    <Col lg={6} md={6}>
                        <img src={team} alt="Our team" className="w-100 h-100 rounded" />
                    </Col>
                </Row>
            </section>
            <section className="container">
                <Row>
                    <Col lg={6} md={6}>
                        <img src={vision} alt="Our Vision" className="w-100 h-100 rounded" />
                    </Col>
                    <Col lg={6} md={6} className="our-vision">
                        <div>
                            <div className="section-title">
                                Our Vision
                            </div>
                            <div className="section-value">
                                Our vision is to be more than just an investment group; we aim to be a trusted partner in our members' financial journey. We envision a community where exclusive access to sophisticated investment strategies and shared wisdom leads to collective and individual prosperity.
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
            <section>
                <div className="container">
                    <div className="section-title">
                        Our Philosophy
                    </div>
                    <div className="section-value">
                        Our investment philosophy is built on three fundamental pillars
                    </div>
                    <div className="border-points mt-4">
                        <div className="title">
                            Innovation in Investment Strategies
                        </div>
                        <div className="value">
                            We harness the power of advanced algorithms and data analytics to uncover unique investment opportunities.
                        </div>
                    </div>
                    <div className="border-points">
                        <div className="title">
                            Personalized Attention
                        </div>
                        <div className="value">
                            Understanding that each member has unique financial goals, we tailor our approach to meet these individual needs.
                        </div>
                    </div>
                    <div className="border-points border-0">
                        <div className="title">
                            Transparency and Integrity
                        </div>
                        <div className="value">
                            We maintain the highest standards of integrity and openness in all our operations, ensuring our members have complete clarity and confidence in our practices.
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light">
                <div className="container p-large">
                    <div className="section-title text-center">
                        Our Commitment
                    </div>
                    <div className="section-value text-center">
                        At Algo Investments Group, our commitment extends beyond financial management. We are dedicated to building lasting relationships with our members, grounded in trust and mutual respect. Our focus is not only on financial gains but also on providing a secure, inclusive, and enriching environment for our members.
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="section-title text-center">
                    Join Us
                </div>
                <div className="section-value text-center">
                    We invite you to become part of a group that values your aspirations as much as your investments. For more information about joining Algo Investments Group, please reach out through our contact page.
                </div>
                <div className="conneect-now">
                    <NavLink to="/contactUs" className="n-button">
                        Connect Now
                    </NavLink>
                </div>
            </section>
        </>
    )
}

export default AboutUs