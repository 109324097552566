import { useEffect, useState } from "react"
import { useLocation, NavLink } from "react-router-dom"
import { Offcanvas, Button } from 'react-bootstrap';

import logo from './../assets/image/logo.png'

const AppHeader = () => {
    const location = useLocation()
    const [isScrolled, setIsScrolled] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleScroll = () => {
        const offset = window.scrollY
        if (offset > 30 && offset < 60) { } else {
            if (offset > 50) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // for smooth scrolling
        })

        // if (location.pathname === '/') {
        setIsScrolled(false)
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
        // } else {
        //     setIsScrolled(true)
        // }
    }, [location])

    return (
        <div className={`app-header ${isScrolled ? 'scrolled' : ''}`}>
            <div className="d-md-none">
                <Button className="hamburger" onClick={handleShow}>
                    <div className="line-1"></div>
                    <div className="line-2"></div>
                    <div className="line-3"></div>
                </Button>

                <Offcanvas show={show} onHide={handleClose} style={{ width: '60%' }}>
                    <Offcanvas.Header closeButton={false}>
                        <Offcanvas.Title>
                            <div className="app-logo">
                                <NavLink to="/" onClick={handleClose}><img src={logo} alt="logo" /></NavLink>
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="app-nav-button">
                            <a className="n-button" href="https://portal.algoinvestmentgroup.com" target="_blank" rel="noreferrer">Sign In</a>
                            <nav>
                                <NavLink to="/" onClick={handleClose}>Home</NavLink>
                                <NavLink to="/aboutUs" onClick={handleClose}>About us</NavLink>
                                <NavLink to="/howItWorks" onClick={handleClose}>How it works</NavLink>
                                <NavLink to="/testimonials" onClick={handleClose}>Testimonials</NavLink>
                                <NavLink to="/contactUs" onClick={handleClose}>Contact us</NavLink>
                            </nav>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>

            <div className="app-logo">
                <NavLink to="/"><img src={logo} alt="logo" /></NavLink>
            </div>
            <div className="app-nav-bar d-none d-md-flex">
                <nav>
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/aboutUs">About us</NavLink>
                    <NavLink to="/howItWorks">How it works</NavLink>
                    <NavLink to="/testimonials">Testimonials</NavLink>
                    <NavLink to="/contactUs">Contact us</NavLink>
                </nav>
            </div>
            <div className="app-nav-button d-none d-md-flex">
                <a className="n-button" href="https://portal.algoinvestmentgroup.com" target="_blank" rel="noreferrer">Sign In</a>
            </div>
        </div>
    )
}

export default AppHeader