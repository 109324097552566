import { NavLink } from "react-router-dom"

const HowItWorks = () => {
    const steps = [
        {
            title: "Invitation and Membership",
            details: [
                {
                    title: "Invitation Process",
                    description: "Membership at Algo Investments Group begins with an invitation. We carefully select potential members who share our vision and commitment to intelligent investing"
                },
                {
                    title: "Joining the Group",
                    description: "Once invited, prospective members are introduced to the group's ethos, investment strategies, and the array of benefits exclusive to our community."
                }
            ]
        },
        {
            title: "Understanding Your Financial Goals",
            details: [
                {
                    title: "Personal Consultation",
                    description: "New members are offered a personal consultation with our investment advisors to thoroughly understand their financial objectives, risk tolerance, and investment preferences."
                },
                {
                    title: "Tailored Strategy",
                    description: "Based on this understanding, we develop a customized investment strategy that aligns with each member's individual goals and investment criteria."
                }
            ]
        },
        {
            title: "Contribution and Investment",
            details: [
                {
                    title: "Funding Your Account",
                    description: "Members contribute funds to their investment account, which will be managed by our team."
                },
                {
                    title: "Diversified Portfolio Management",
                    description: "Our experts actively manage and diversify the investments, utilizing advanced algorithms and insightful market analysis to optimize returns. "
                }
            ]
        },
        {
            title: "Monitoring and Reporting ",
            details: [
                {
                    title: "Continuous Oversight",
                    description: "Our team continuously monitors the market and adjusts strategies as necessary to protect and grow your investments. "
                },
                {
                    title: "Transparent Reporting",
                    description: "Members receive regular, detailed reports on the performance of their investments, ensuring transparency and keeping them informed every step of the way. "
                }
            ]
        },
        {
            title: "Returns and Reinvestment ",
            details: [
                {
                    title: "Distribution of Returns",
                    description: "Profits generated from investments are either distributed to members as per the agreed terms or reinvested, according to individual member preferences. "
                },
                {
                    title: "Ongoing Reinvestment Opportunities",
                    description: "Members have the option to reinvest their returns to further compound their investment growth."
                }
            ]
        },
        {
            title: "Ongoing Support and Advice",
            details: [
                {
                    title: "Accessible Expertise",
                    description: "Our team remains available to provide ongoing support, advice, and insights to ensure members are fully engaged and informed about their investments. "
                },
                {
                    title: "Adjustments and Changes",
                    description: "Members have the flexibility to adjust their investment strategies in response to changing financial goals or market conditions. "
                }
            ]
        }
    ]
    return (
        <>
            <section className="home-banner how-it-works-banner">
                <div className="page-title">How It Works</div>
                <div className="home-banner-text"></div>
            </section>
            <section>
                <div className="container">
                    {
                        steps.map((step, index) => {
                            return (
                                <div className="steps-container">
                                    <div className="steps">
                                        <div className="steps-count">{index + 1}</div>
                                        <div className="steps-title">{step.title}</div>
                                    </div>
                                    {
                                        step.details.map((detail, dIndex) => {
                                            return (
                                                <section className="steps-section">
                                                    <div className="steps-section-title">
                                                        {detail.title}
                                                    </div>
                                                    <div className="step-section-value">
                                                        {detail.description}
                                                    </div>
                                                </section>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </section>
            <section className="container">
                <div className="section-title text-center">
                    Join the Algo Investments Group Community
                </div>
                <div className="section-value text-center">
                    Express Your Interest: If you are interested in becoming a member and accessing the exclusive investment opportunities at Algo Investments Group, please visit our Contact page to get in touch.
                </div>
                <div className="conneect-now">
                    <NavLink to="/contactUs" className="n-button">
                        Connect Now
                    </NavLink>
                </div>
            </section>
        </>
    )
}

export default HowItWorks