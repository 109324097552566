import React, { Component } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap-icons/font/bootstrap-icons.css";
import './App.css';

import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'
import Home from './page/home/Home'
import AboutUs from './page/aboutUs/AboutUs'
import HowItWorks from './page/howItWorks/HowItWorks'
import Testimonials from './page//testimonials/Testimonials'
import ContactUs from './page/contactUs/ContactUs'

class App extends Component {
  render() {
    return (
      <HashRouter>
        <AppHeader />
        <div className="app-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route exact path="/home" name="Home Page" element={<Home />} />
            <Route exact path="/aboutUs" name="About Us Page" element={<AboutUs />} />
            <Route exact path="/howItWorks" name="How It Works Page" element={<HowItWorks />} />
            <Route exact path="/testimonials" name="Testimonials Page" element={<Testimonials />} />
            <Route exact path="/contactUs" name="Contact Us Page" element={<ContactUs />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <AppFooter />
      </HashRouter>
    )
  }
}

export default App;
