import React, { useState } from 'react'
import { Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

const ContactUs = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [showLoader, setShowLoader] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)

    const onSubmit = async (data) => {
        setShowLoader(true)
        fetch('https://api.piccotrading.com/public/algo/contactUs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            return res.json()
        }).then(res => {
            setShowLoader(false)
            if (res.status) {
                reset()
                setShowSuccessModal(true)
            } else {
                setShowErrorModal(true)
            }
        }).catch(error => {
            setShowErrorModal(true)
        })
    }

    const onError = (data) => {
        // console.log(data)
    }

    const handleModalClose = () => {
        setShowSuccessModal(false)
        setShowErrorModal(false)
    }

    return (
        <>
            <section className="home-banner contactus-banner">
                <div className="page-title">Contact Us</div>
                <div className="home-banner-text"></div>
            </section>
            <section className="container">
                <div className="section-title text-center">
                    Connect with Algo Investments Group
                </div>
                <div className="section-value text-center">
                    We welcome your interest in Algo Investments Group and are here to provide you with more information, answer any questions you may have, and create an effective solution for your investment needs.
                </div>
            </section>
            <section className="container">
                <Row>
                    <Col lg={6} md={6}>
                        <section>
                            <div className="section-title">
                                Get in Touch
                            </div>
                            <div className="section-value">
                                <b>Email Us:</b> For general inquiries or specific questions, please email us at
                                <a className="ms-2" href="mailto:info@algoinvestmentgroup.com?subject=Inquiry&body=Hello, I would like to ask about...">
                                    info@algoinvestmentgroup.com
                                </a>
                                .
                            </div>
                            {/* <br></br>
                            <div className="section-value">
                                Visit us at our office for a personal meeting. Please schedule an appointment in advance to ensure we can provide you with dedicated time and attention.
                            </div> */}
                        </section>
                        {/* <section className="conatctus-box">
                            <div className="section-title">
                                Schedule a Meeting
                            </div>
                            <div className="section-value">
                                <b>Meeting Request:</b> Interested in discussing your investment goals or learning more about our group? Schedule a meeting with one of our advisors. Please fill out our online form here or contact us through the email to set up an appointment.
                            </div>
                        </section> */}
                        <section className="conatctus-box">
                            <div className="section-title">
                                Express Your Interest in Joining
                            </div>
                            <div className="section-value">
                                If you are interested in becoming a member of Algo Investments Group, please visit our Membership Inquiry page or contact us directly using the information above. We look forward to the possibility of welcoming you to our exclusive investment community.
                            </div>
                        </section>
                    </Col>
                    <Col lg={6} md={6}>
                        <Form onSubmit={handleSubmit(onSubmit, onError)} className="contctus-form bg-light">
                            <Form.Group className="mb-4" controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter you name"
                                    {...register('name', { required: 'Name is required' })}
                                />
                                {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter you email"
                                    {...register('email', {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                />
                                {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formMessage">
                                <Form.Label>How Can We Help You?</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="Write your message here"
                                    {...register('message', { required: 'Message is required' })}
                                />
                                {errors.message && <Form.Text className="text-danger">{errors.message.message}</Form.Text>}
                            </Form.Group>

                            <Button type="submit" className="n-button mt-2">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </section>
            {
                showLoader && (
                    <div className="full-page-loader">
                        <Spinner animation="border" variant="light" role="status">
                            <span className="sr-only"></span>
                        </Spinner>
                    </div>
                )
            }
            <Modal centered show={showSuccessModal} onHide={handleModalClose}>
                <Modal.Header className="border-0">
                    <Modal.Title className="m-auto text-center text-success">
                        <i class="bi bi-check-circle-fill"></i>
                        <div>Success!</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p style={{ fontSize: '20px' }} className="text-muted">Your message has been successfully submitted. We'll get back to you soon!</p>
                </Modal.Body>
                <Modal.Footer className="border-0 text-center">
                    <Button variant="success" size="lg" className="m-auto rounded-0" style={{ width: '175px' }} onClick={handleModalClose} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={showErrorModal} onHide={handleModalClose}>
                <Modal.Header className="border-0">
                    <Modal.Title className="m-auto text-center text-danger">
                        <i class="bi bi-x-circle-fill"></i>
                        <div>Error!</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p style={{ fontSize: '20px' }} className="text-muted">
                        Something went wrong!
                        <br></br>
                        Please try again.
                    </p>
                </Modal.Body>
                <Modal.Footer className="border-0 text-center">
                    <Button variant="danger" size="lg" className="m-auto rounded-0" style={{ width: '175px' }} onClick={handleModalClose} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ContactUs