import { Container, Row, Col } from 'react-bootstrap'

const Testimonials = () => {
    const testimonials = [
        {
            title: "A Game-Changer in My Investment Journey",
            name: "John D",
            background: "Experienced investor looking for innovative strategies.",
            testimonial: "Joining Algo Investments Group was a turning point for me. The personalized approach and advanced investment strategies have significantly enhanced my portfolio's performance. The team's expertise and commitment to my financial goals have been nothing short of exceptional.",
        },
        {
            title: "From Skeptic to Believer",
            name: "Sarah L",
            background: "New to investment groups, seeking secure and profitable opportunities.",
            testimonial: "I was initially skeptical about joining an exclusive investment group. However, the transparency and professionalism at Algo Investments Group won me over. I've seen remarkable growth in my investments and feel fully supported by their expert team.",
        },
        {
            title: "Expertise That Delivers Results",
            name: "Michael T",
            background: "Seasoned investor, looking for high-performance opportunities",
            testimonial: "The level of expertise and market insight at Algo Investments Group is impressive. They've consistently delivered strong returns, and their proactive approach to portfolio management keeps me confident and informed.",
        },
        {
            title: "Trust and Performance",
            name: "Emma R",
            background: "Conservative investor focused on steady growth",
            testimonial: "What I value most about Algo Investments Group is the trust I've developed with their team. They understand my conservative approach and have tailored my investments accordingly, achieving steady and reliable growth.",
        }
    ];

    return (
        <>
            <section className="home-banner testimonials-banner">
                <div className="page-title">Testimonials</div>
                <div className="home-banner-text"></div>
            </section>
            <section className="container">
                <div className="section-title text-center">
                    Real Stories from Our Members: The Impact of Algo Investments Group
                </div>
                <div className="section-value text-center">
                    At Algo Investments Group, we take pride in the positive experiences and successes of our members. Here are some of their stories, showcasing the impact of our partnership on their financial journeys.
                </div>
            </section>
            <section>
                <Container className="testimonial my-5">
                    <Row>
                        {testimonials.map((testimonial, index) => (
                            <Col md={6} key={index} className="p-4">
                                <div className="testimonial-card">
                                    <h3 className="testimonial-title">{testimonial.title}</h3>
                                    <p className="testimonial-text">{testimonial.testimonial}</p>
                                    <div className="testimonial-author">
                                        <strong>{testimonial.name}</strong>
                                        <br></br>
                                        <small className="text-muted">{testimonial.background}</small>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Testimonials