import { NavLink } from "react-router-dom"
import { Row, Col } from 'react-bootstrap'

import logo from './../assets/image/logo.png'

const AppHeader = () => {
    return (
        <div className="app-footer">
            <div className="container my-0">
                <Row>
                    <Col lg={3} md={3} sm={12}>
                        <div className="app-footer-logo">
                            <NavLink to="/"><img src={logo} alt="logo" /></NavLink>
                        </div>
                        <div className="copyright">
                            Copyright © 2024 Algo Investment Group
                        </div>
                    </Col>
                    <Col lg={9} md={9} sm={12} className="app-nav-bar">
                        <nav>
                            <NavLink to="/">Home</NavLink>
                            <NavLink to="/aboutUs">About us</NavLink>
                            <NavLink to="/howItWorks">How it works</NavLink>
                            <NavLink to="/testimonials">Testimonials</NavLink>
                            <NavLink to="/contactUs">Contact us</NavLink>
                        </nav>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default AppHeader